import {GameValues} from "~/@types";

export function getRandomNumber(min: number, max: number): number {
  return Math.round(Math.random() * (max - min) + min);
}

export function shuffleArray(array: any[]): any[] {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function getRandomBool(): boolean {
  return !!getRandomNumber(0, 1);
}

export function animateValue(id: string, start: number, end: number, durationMS: number) {
  const range: number = end - start;
  let current: number = start;
  const increment: number = end > start ? 1 : -1;
  const stepTime = Math.abs(Math.floor(durationMS / range));
  const obj = document.getElementById(id);
  if (obj) {
    const timer = setInterval(() => {
      current += increment;
      obj.innerHTML = current.toString();
      if (current === end) {
        clearInterval(timer);
      }
    }, stepTime);
  }
}

export function getId(): string {
  return Date.now().toString();
}
// animateValue('value', 100, 25, 5000)

export function sortNumbers(numbers: number[]): void {
  function sortNumber(a: number, b: number) {
    return a - b;
  }

  numbers.sort(sortNumber);
}



export function forceHardShuffle(values: GameValues[]): GameValues[] {
  const newVals: GameValues[] = [];

  let isDone: boolean = false;
  while (!isDone) {
    for (let i = values.length - 1; i > -1; i--) {
      if (newVals.length == 0) {
        newVals.push(values[i]);
        values.splice(i, 1);
      } else {
        if (newVals[newVals.length - 1].answer !== values[i].answer) {
          newVals.push(values[i]);
          values.splice(i, 1);
        }
      }
    }
    if (values.length === 0) {
      isDone = true;
    } else if (remainingAreSame(values)) {
      // all teh values are the same as the last value
      mergeRemainingValue(values, newVals);
    }
  }
  return newVals;
}

function mergeRemainingValue(remaining: GameValues[], values: GameValues[]) {
  const remainingAnswer: number = remaining[0].answer;
  if (values[0].answer !== remaining[0].answer) {
    values.unshift(remaining[0]);
    remaining.splice(0, 1);
  }
  const maxPasses = 5;
  let passCount = 0;

  while (remaining.length > 0 || passCount < maxPasses) {
    for (let i = values.length - 2; i > -1; i--) {
      if (remaining.length > 0) {
        if (values[i].answer !== remainingAnswer && values[i + 1].answer !== remainingAnswer) {
          values.splice(i + 1, 0, remaining[0]);
          remaining.splice(0, 1);
        }
      }
    }
    passCount++;
  }
  if (remaining.length > 0) {
    // nothing to do but add randomly
    values.splice(values.length - 1, 0, ...remaining);
  }
}

function remainingAreSame(values: GameValues[]) {
  const keyVals: any = {};
  for (const val of values) {
    keyVals[val.answer.toString()] = val;
  }

  return Object.keys(keyVals).length === 1;
}
