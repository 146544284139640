import { AppState } from '../@types/store';
import { GameStat, GameUser } from '../@types';
import _ from 'lodash';

export default {
  startActivity(state: AppState) {
    state.activity = true;
  },
  endActivity(state: AppState) {
    state.activity = false;
  },
  setAppLoaded(state: AppState) {
    state.appLoaded = true;
  },
  setUsers(state: AppState, users: GameUser[]) {
    state.users = users;
  },
  setActiveUserId(state: AppState, userId: string) {
    state.activeUserId = userId;
    const activeUser = _.find(state.users, { id: state.activeUserId }) as GameUser;
    if (activeUser) {
      state.totalStars = getPlayerStars(activeUser.gameStats);
    }
    // set active user object from users
  },
  // setActiveUser(state: AppState, user:GameUser) {
  //   state.activeGameUser = userId;
  // },
  setCookieConfirmed(state: AppState) {
    state.cookieConfirmed = true;
  },
  addUser(state: AppState, user: GameUser) {
    state.users.push(user);
  },
  setMusicMuted(state: AppState, muted: boolean) {
    state.musicMuted = muted;
  },
  setSoundMuted(state: AppState, muted: boolean) {
    state.soundMuted = muted;
  },
  addStars(state: AppState, starCount: number) {
    state.totalStars = state.totalStars + starCount;
  },
  setPlayerStars(state: AppState, starCount: number) {
    state.totalStars = starCount;
  },
  updateActiveLevel(state: AppState, { gameCode, levelId }:any) {
    const activeUser = _.find(state.users, { id: state.activeUserId }) as GameUser;
    activeUser.activeLevels = activeUser.activeLevels || {};
    activeUser.activeLevels[gameCode] = levelId;
  },
  addGameStat(state: AppState, stat: GameStat) {
    const activeUser = _.find(state.users, { id: state.activeUserId }) as GameUser;
    activeUser.gameStats.push(stat);
  },
  removeUser(state: AppState, playerId: string) {
    state.users = _.filter(state.users, user => {
      return user.id !== playerId;
    });
  }
};

function getPlayerStars(gameStats: GameStat[]) {
  let count: number = 0;
  gameStats.forEach(stat => {
    count = count + stat.stars;
  });
  return count;
}
