import { mapMutations, mapGetters, mapActions } from 'vuex';
import Vue from 'vue';
export default Vue.extend({
  name: 'CookieConfirm',
  data() {
    return {
      visible: true
    };
  },
  created() {},
  mounted() {
    const cookiesAccepted = localStorage.getItem('phcm');
    if (cookiesAccepted === '1') {
      this.setCookieConfirmed();
    }
  },
  methods: {
    ...mapMutations(['setCookieConfirmed']),
    ...mapActions(['acceptCookieMessage']),
    acceptCookie() {
      this.acceptCookieMessage();
      setTimeout(() => {
        // hide the component
        this.visible = false;
      }, 2000);
    }
  },
  computed: {
    ...mapGetters(['showCookieMessage'])
  }
});
