import { AppState } from '../@types/store';

export default (): AppState => ({
  activeUserId: '',
  users: [],
  cookieConfirmed: false,
  appLoaded: false,
  activity: false,
  musicMuted: false,
  soundMuted: false,
  totalStars: 0
});
