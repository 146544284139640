import { ActionContext } from 'vuex';
import { AppState } from '../@types/store';
import { getId } from '../services/utils';
import { AppUserProfiles, GameStat, GameUser } from '../@types';
import _ from 'lodash';

export default {
  addGameStat,
  addUser,
  setMusicMuted,
  setSoundMuted,
  getUsers,
  selectPlayer,
  acceptCookieMessage,
  deletePlayer,
  saveUsersToStorage
};

function acceptCookieMessage({ commit }: ActionContext<AppState, any>) {
  localStorage.setItem('phcm', '1');
  commit('setCookieConfirmed');
}

function getUsers({ commit }: ActionContext<AppState, any>) {
  // SPA ONLY
  // read localstorage
  const userProfileData = localStorage.getItem('players');
  if (userProfileData) {
    let users: GameUser[] = [];
    let activeUserId = '';
    let soundMuted: boolean = false;
    let musicMuted: boolean = false;

    try {
      const userProfiles: AppUserProfiles = JSON.parse(userProfileData);
      if (userProfiles.users && userProfiles.users.length) {
        users = userProfiles.users;
        activeUserId = userProfiles.activeUserId || userProfiles.users[0].id;
        soundMuted = userProfiles.soundMuted || false;
        musicMuted = userProfiles.musicMuted || false;
      }
    } finally {
      commit('setUsers', users);
      commit('setMusicMuted', musicMuted);
      commit('setSoundMuted', soundMuted);
      commit('setActiveUserId', activeUserId);
      // commit('setPlayerStars', getPlayerStars(activeUser.gameStats));
    }
  }
}

async function setMusicMuted({ commit, state }: ActionContext<AppState, any>, muted: boolean) {
  commit('setMusicMuted', muted);
  saveUsersToStorage({ state } as ActionContext<AppState, any>);
}

async function setSoundMuted({ commit, state }: ActionContext<AppState, any>, muted: boolean) {
  commit('setSoundMuted', muted);

  saveUsersToStorage({ state } as ActionContext<AppState, any>);
}

async function addGameStat({ commit, state }: ActionContext<AppState, any>, gameStat: GameStat) {
  // get active user
  commit('addGameStat', gameStat);

  saveUsersToStorage({ state } as ActionContext<AppState, any>);
}

async function selectPlayer({ commit, state, dispatch }: ActionContext<AppState, any>, playerId: string) {
  commit('setActiveUserId', playerId);
  const activeUser = _.find(state.users, { id: state.activeUserId }) as GameUser;

  saveUsersToStorage({ state } as ActionContext<AppState, any>);
}

async function deletePlayer({ commit, state }: ActionContext<AppState, any>, playerId: string) {
  commit('removeUser', playerId);
  commit('setActiveUserId', '');
  saveUsersToStorage({ state } as ActionContext<AppState, any>);
}

async function addUser({ commit, state, dispatch }: ActionContext<AppState, any>, name: string): Promise<boolean> {
  if (!name || !name.trim()) {
    // Error
    return false;
  }

  const user: GameUser = {
    name: name,
    id: getId(),
    gameStats: [],
    activeLevels: {},
    activeGameId: undefined
  };

  commit('addUser', user);
  commit('setActiveUserId', user.id);

  saveUsersToStorage({ state } as ActionContext<AppState, any>);
  return true;
}

function saveUsersToStorage({ state }: ActionContext<AppState, any>) {
  const userProfiles: AppUserProfiles = {
    users: state.users,
    activeUserId: state.activeUserId,
    soundMuted: state.soundMuted,
    musicMuted: state.musicMuted
  };

  localStorage.setItem('players', JSON.stringify(userProfiles));
}
