import Splash from '../components/splash';
import CookieMessage from '../components/cookie-message';
import { mapMutations } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
  name: 'Layout',
  components: { Splash, CookieMessage },
  middleware: ['auth'],
  data() {
    return {
      splashVisible: false
    };
  },
  created() {},
  mounted() {
    this.splashVisible = true;
    setTimeout(() => {
      this.splashVisible = false;
      this.setAppLoaded();
    }, 2000);
  },
  methods: {
    ...mapMutations(['setAppLoaded'])
  },
  computed: {}
});
