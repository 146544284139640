export default {
  name: 'Splash',
  props: ['visible'],
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {}
};
