import { Context as NuxtAppContext } from '@nuxt/types';
export default async function({ store, redirect, route }:NuxtAppContext) {
  if (!store.state.appLoaded) {
    await store.dispatch('getUsers');

    // if (store.state.activeUserId){
    //   // load the active user
    //   await store.dispatch('loadUserState',store.state.activeUserId)
    // }

    store.commit('setAppLoaded');
  }

  if (!store.state.activeUserId && route.name !== 'login') {
    redirect('/login');
  }
}
