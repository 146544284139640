import { AppState } from '../@types/store';
import { GameStat, GameUser } from '../@types';
import _ from 'lodash';

export default {
  isBusy(state: AppState): boolean {
    return state.activity;
  },
  activeUserId(state: AppState): string {
    return state.activeUserId;
  },
  users(state: AppState): GameUser[] {
    return state.users;
  },
  showCookieMessage(state: AppState): boolean {
    return !state.cookieConfirmed && state.appLoaded;
  },
  activeUser(state: AppState): GameUser | undefined {
    return _.find(state.users, { id: state.activeUserId });
  },
  musicMuted(state: AppState): boolean {
    return state.musicMuted;
  },
  soundMuted(state: AppState): boolean {
    return state.soundMuted;
  },
  totalStars(state: AppState): number {
    return state.totalStars;
  }
};
