import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66c412a9 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _9a65e7a8 = () => interopDefault(import('../pages/game/index.vue' /* webpackChunkName: "pages/game/index" */))
const _6dacbb85 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _3d46f114 = () => interopDefault(import('../pages/game/components/answer-splash/index.vue' /* webpackChunkName: "pages/game/components/answer-splash/index" */))
const _0ce14ddb = () => interopDefault(import('../pages/game/components/answers-bar/index.vue' /* webpackChunkName: "pages/game/components/answers-bar/index" */))
const _1eca69a7 = () => interopDefault(import('../pages/game/components/basic-game/index.vue' /* webpackChunkName: "pages/game/components/basic-game/index" */))
const _322ad605 = () => interopDefault(import('../pages/game/components/cancel-game/index.vue' /* webpackChunkName: "pages/game/components/cancel-game/index" */))
const _b016df12 = () => interopDefault(import('../pages/game/components/correct-answer/index.vue' /* webpackChunkName: "pages/game/components/correct-answer/index" */))
const _19ba2447 = () => interopDefault(import('../pages/game/components/game-intro/index.vue' /* webpackChunkName: "pages/game/components/game-intro/index" */))
const _03e33bd0 = () => interopDefault(import('../pages/game/components/level-complete/index.vue' /* webpackChunkName: "pages/game/components/level-complete/index" */))
const _81965aae = () => interopDefault(import('../pages/game/components/progress-bar/index.vue' /* webpackChunkName: "pages/game/components/progress-bar/index" */))
const _21e06bdc = () => interopDefault(import('../pages/game/components/question/index.vue' /* webpackChunkName: "pages/game/components/question/index" */))
const _2d781d70 = () => interopDefault(import('../pages/game/components/sound-toggle/index.vue' /* webpackChunkName: "pages/game/components/sound-toggle/index" */))
const _44da78c4 = () => interopDefault(import('../pages/game/components/trophies/index.vue' /* webpackChunkName: "pages/game/components/trophies/index" */))
const _1a22d058 = () => interopDefault(import('../pages/login/components/delete-user/index.vue' /* webpackChunkName: "pages/login/components/delete-user/index" */))
const _9486092a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _66c412a9,
    name: "about"
  }, {
    path: "/game",
    component: _9a65e7a8,
    name: "game"
  }, {
    path: "/login",
    component: _6dacbb85,
    name: "login"
  }, {
    path: "/game/components/answer-splash",
    component: _3d46f114,
    name: "game-components-answer-splash"
  }, {
    path: "/game/components/answers-bar",
    component: _0ce14ddb,
    name: "game-components-answers-bar"
  }, {
    path: "/game/components/basic-game",
    component: _1eca69a7,
    name: "game-components-basic-game"
  }, {
    path: "/game/components/cancel-game",
    component: _322ad605,
    name: "game-components-cancel-game"
  }, {
    path: "/game/components/correct-answer",
    component: _b016df12,
    name: "game-components-correct-answer"
  }, {
    path: "/game/components/game-intro",
    component: _19ba2447,
    name: "game-components-game-intro"
  }, {
    path: "/game/components/level-complete",
    component: _03e33bd0,
    name: "game-components-level-complete"
  }, {
    path: "/game/components/progress-bar",
    component: _81965aae,
    name: "game-components-progress-bar"
  }, {
    path: "/game/components/question",
    component: _21e06bdc,
    name: "game-components-question"
  }, {
    path: "/game/components/sound-toggle",
    component: _2d781d70,
    name: "game-components-sound-toggle"
  }, {
    path: "/game/components/trophies",
    component: _44da78c4,
    name: "game-components-trophies"
  }, {
    path: "/login/components/delete-user",
    component: _1a22d058,
    name: "login-components-delete-user"
  }, {
    path: "/",
    component: _9486092a,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
